.section.hero {
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
    border: 0;


    .wrapper {
        width: 100%;
        max-width: 100%;
        max-height: calc(100vh - 150px);
        overflow: hidden;
        position: relative;
        background: transparent;
        margin-bottom: -1px;
        
        @media screen and (min-width: 1400px) {
            height: 75vh;
            width: 100vw;     
            min-height: 300px;       
        }


        @include media-phone-only() {
            height: 50vh;
            max-height: calc(100vh -  100px);
            margin-bottom: -15px;
        }

        video, .hero-image {
            width: 100%;
            height: auto;
            border: 0;
            background: transparent;
            margin-top: -10px;
            margin-bottom: -10px;

            @media screen and (min-width: 1400px) {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            

            @include media-phone-only() {
                position: absolute;
                left: 50%;
                top: 0;
                height: 50vh;
                width: 120vh;
                transform: translateX(-50%);
            }
        
        }

        .content{
            position: absolute;
            right: 20px;
            bottom: 11%;
            width: calc(35% - 20px);
            @include media-phone-only() {
                width: calc(50% - 20px);
                right: 20px;
                bottom: 10px;
            }

            .logo-image {
                height: 180px;
                width: auto;
                margin-left: -110px;
                @include media-desktop-down() {
                    height: 10vw;
                    margin-left: -6vw;
                }
                @include media-tablet-down() {
                    display: none;
                }

            }

            p {
                color: $white;
                font-size: 21px;
                margin-bottom: 1em;
                margin-top: 1em;
                text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
                @include media-desktop-down() {
                    font-size: 15px;
                }
                @include media-phone-only() {
                    font-size: 10px;
                    text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
                    margin-left: -5vw;
                }
            }

            h4 {
                color: $highlight;
                font-size: 21px;
                text-transform: uppercase;
                text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;

                @include media-phone-only() {
                    font-size: 15px;
                    margin-left: -5vw;
                }
            }
        }
    }
}