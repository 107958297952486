@mixin media-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin media-tablet-down {
    @media (max-width: 970px) { @content; }
}
@mixin media-desktop-down {
    @media (max-width: 1350px) { @content;}
}
@mixin media-desktop-up {
    @media (min-width: 1930px) { @content; }
}

@mixin backgroundImage($path,$position:0 0,$repeat: no-repeat) {
    background: {
        image: url($assets + $path);
        position: $position;
        repeat: $repeat;
    }
}