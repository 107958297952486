.section.footer {
    margin-top: 0;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bgcolor;

    

    @include media-tablet-down {
        padding: 20px 0;
        margin-top: 0;
    }

    > .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-grow: 1;
    }
    
    
    footer {
        margin: 0;
        padding: 0;
        color: $white;

        z-index: 170;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;

        @include media-tablet-down {
            flex-wrap: wrap;
            height: auto;
            padding: 10px 0;
        }

    }
    
    .logo {
        display: block;
        margin-right: 40px;
        height: auto;
        @include media-desktop-down {
            margin-right: 20px;
        }
        @include media-tablet-down {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        img {
            width: auto;
            height: 85px;
            transition: .5s ease all;
            @include media-desktop-down {
                height: 60px;
            }
            @include media-tablet-down {
                height: 50px;
            }
            
            &:hover {
                transform: scale(1.1);
            }
        }

    }


    .text {
        width: 485px;
        max-width: calc(100vw - 596px);
        font-size: 21px;
        @include media-tablet-down {
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 40px 0;
        }
        .navlink {
            color: $highlight;
            text-decoration: none;
            display: inline-block;
            padding-top: 5px;
        }
    }

    .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        @include media-tablet-down {
            margin-top: 20px;
            width: 100%;
        }
        .social {
            display: block;
            width: 33px;
            height: 33px;
            margin-left: 10px;
            background-size: contain;
            cursor: pointer;
            transition: .5s ease;
            border-radius: 50%;
            transition: .5s ease;

            &.steam {@include backgroundImage('/images/social-steam.png')}
            &.instagram {@include backgroundImage('/images/social-instagram.png')}
            &.x {@include backgroundImage('/images/social-x.png')}
            &.youtube {@include backgroundImage('/images/social-youtube.png')}
            &.facebook {@include backgroundImage('/images/social-facebook.png')}
            &.discord {@include backgroundImage('/images/social-discord.png')}

            &:hover {
                box-shadow: 0 0 0 2px $darkyellow;
                background-color: $darkyellow;
                filter:  contrast(3) brightness(1.4);
            }


        }
    }


}
