.section.video-section {
    display: block;
    width: 100%;
    @include media-phone-only() {
        background-color: rgba(#1e7177, 1.0);

        padding: 0;
        margin: 0;
    }

    .section-title {
        padding: 0;
        margin-top: 1.25em;
        box-sizing: border-box;
        
        @include media-phone-only() {
            margin: 0 auto 0.4em;
            padding-top: 0.8em;
        }
    }        

    .video {
        padding: 25px 0 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include media-phone-only() {
            padding: 0;
        }

        .video-border {
            position: relative;
            width: 80vw;
            height: 40vw;
            padding: 25px;
            @include backgroundImage('images/video-border.jpg');
            background-size: cover;

            
            @media (min-width: 1350px) {
                width: 45vw;
                height: 22.5vw;
            }

            @include media-phone-only() {
                padding: 10px;
                width: 100%;
                height: 53vw;
            }
            
            iframe {
                background: black;
                width: 100%;
                height: 100%;
            }
            

        }

   
    }
}