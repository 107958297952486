.section.key-features { 
    display: block;
    background-color: $black;
    
    .section-title { 
        padding: 0;
        margin-bottom: 25px;
        padding-bottom: 0;
    }


	.wrapper { 
        
        width: 100%;
        max-width: 100%;
        background-color: #1e7076;

		.feature-list { 
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            

			.feature { 
                width: 100%;
                max-width: 100%;
                box-sizing: border-box;
                text-align: left;
                padding: 30px;
                background-color: #1e7076;
                background-size: cover;
                background-position: center center;
                align-items: center;
                justify-content: center;
                display: flex;

                &.with-bg {
                    min-height: 330px;
                    align-items: flex-end;
                }

  
                @include media-tablet-down() {
                    //width: 50%;
                }
                @include media-phone-only() {
                    //width: 100%;
                }

                .sizer {
                    width: 750px;
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    @include media-desktop-down() {
                        flex-wrap: wrap;
                    }
                }

				img { 
                    height: 210px;
                    width: auto;
                    max-width: 100%;
                    @include media-desktop-down() {
                        margin: 0 auto;
                    }
                    @include media-phone-only() {
                        width: 100%;
                        height: auto;
                    }
				}

                .text-content {
                    width: calc(50% - 30px);
                    background-color: rgba($black, 0.6);
                    padding: 30px;

                    @include media-desktop-down() {
                        width: 100%;
                    }
                   
                    &.padding {
                        margin-left: 50px;
                        background-color: transparent;
                        width: 100%;
                        @include media-desktop-down() {
                            margin-left: 0;
                            padding: 20px 0 0;

                        }
                    }

                        
                    h4 {
                        font-size: 21px;
                        color: $gold;
                        font-weight: bold;
                        margin-bottom: 0.6em;
                        line-height: 120%;
                    }

                    p { 
                        font-size: 21px;
                        line-height: 120%;
                    }
                }

			}
		}

	}
}