.section.about-the-game {
    display: block;
    @include  backgroundImage('images/about-bg.webp  ');
    background-size: cover;
    background-attachment: fixed;
    background-position: center right;
    background-color: $black;



    .about-book {
        font-family: 'Pangolin', cursive;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1300px;
        max-width: calc(100% - 80px);
        margin: 0 auto;
        padding: 55px 0;

        @include media-tablet-down() {
            width: 100%;
            max-width: 100%;
            padding: 20px 0;
        }
        @include media-phone-only() {
            flex-wrap: wrap;
        }

        .page {
            box-sizing: border-box;
            width: 50%;
            padding-top: 63.148%;
            position: relative;
            color: $black;
            &:first-child {@include backgroundImage('images/page-left.webp');}
            &:last-child {@include backgroundImage('images/page-right.webp');}
            background-size: contain;

            @include media-phone-only() {
                width: 100%;
                padding-top: 126.296%;
            }

            .page-content {
                box-sizing: border-box;
                padding: 8% 10%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 1.5vw;
                @include media-phone-only() {
                    font-size: 3vw;
                }
                
                @media (min-width: 1300px) {
                    font-size: 22px;                    
                }


                .wide-image {
                    margin-top: -1.5em;
                    max-width: none;
                    height: auto;
                    width: calc(100% + 4.5em);
                    margin-bottom: 0.5em;
                    margin-left: -1em;
                }

                h2 {
                    font-size: 1.6em;
                    text-decoration: underline;
                    margin-bottom: 0.2em;
                    margin-top: 0.6em;
                    &.rotation-a{transform: rotate(1.5deg);}
                    &.rotation-b{transform: rotate(-0.5deg);}
                    
                }

                h3 {
                    font-size: 1.7em;
                    transform: rotate(-3.5deg);
                }


                p {
                    margin-bottom: 0.5em;
                    transform: rotate(1deg);
                    &:nth-child(even) {
                        transform: rotate(-0.5deg);
                        font-size: 0.97em;
                    }
                    &.rotation-c {transform: rotate(-1.5deg);}
                    &.rotation-d {transform: rotate(1.5deg);}
                }

                .two-col {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 0.5em;
                    margin-top: 0.5em;
                    position: relative;

                    &:first-child {
                        margin-top: 0;
                    }

                    img {
                        width: 45%;
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    h2, p {
                        display: flex;
                        align-items: center;
                        width: 50%;
                    }

                    *:first-child {
                        margin-right: 2.5%;
                    }

                    *:last-child {
                        margin-left: 2.5%;
                    }


                }
            }

            &:first-child {
                .page-content {
                    padding-left: 20%;
                }
            }
            &:last-child {
                .page-content {
                    padding-right: 20%;
                }
            }

            
            .duddle {
                position: relative;

                &.duddle-noir:after {
                    @include backgroundImage('images/duddle-play-in-noir.png');
                    top: 0;
                    right: -8%;
                    background-position-x: right;
                }

                &.duddle-star:after {
                    @include backgroundImage('images/duddle-star.png');
                    top: -56%;
                    left: -3%;
                    background-position-x: left;
                    width: 50%;
                    height: 50%;
                }

                &.duddle-top-left:after {
                    @include backgroundImage('images/duddle-left-top.png');
                    top: 10%;
                    left: -8%;
                    background-position-x: left;
                    width: 80%;
                    height: 80%;
                }

                &:after {
                    pointer-events: none;
                    background-size: contain !important;
                    position: absolute;
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 105%;
                }

            }
        }
    }
}