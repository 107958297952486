body {
    background-color: $bgcolor;
    color: $white;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
    background-size: 100vw;
    background-position: center;

    @include backgroundImage('images/colorbg.jpg');
    background-size: cover;
    background-attachment: fixed;


    @include media-phone-only() {
        background-attachment: scroll;
        background-size: 300vh;
        background-repeat: repeat;
    }
}

* {
    font-family: 'Oswald', sans-serif;
}


.section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    .wrapper {
        width: 1074px;
        max-width: calc(100% - 60px);
        margin: 0px auto;
        display: block;
    }

    .section-title {
        color: $gold;
        width: 100%;
        text-align: center;
        font-size: 40px;
        text-shadow: rgba(0, 0, 0, 0.4) 2px 0px 0px;

        @include media-desktop-down() {
            font-size: 2.0em;
        }

        @include media-phone-only() {
            font-size: 1.8em;
        }
    }
}

