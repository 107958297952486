.section.about-b { 
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 45px;
    position: relative;
    border-bottom: 150px solid $black;

    @include backgroundImage('images/colorbg.jpg');
    background-size: cover;
    background-attachment: fixed;

    @include media-desktop-down() {
        justify-content: flex-start;
    }


    @include media-phone-only() {
        flex-wrap: wrap;
        margin-top: 0;
        padding-top: 0;
    }    

    
	.side { 
        height: 100%;
        width: 25%;
        @include media-desktop-down() {
            width: 30%;
        }
        @include media-phone-only() {
            width: 100%;
            position: relative;
            height: 100vw;
            width: 100%;
        }
        @include media-phone-only() {
            display: none;
        }

        &:after {
            content: " ";
            display: block;
            width: 30%;
            top: 0;
            position: absolute;
            height: 100%;
            background-position: bottom center;
            background-size: contain;
            
            @include media-desktop-down() {
                width: 35%;
            }

            @include media-tablet-down() {
                width: 50%;
            }

            @include media-phone-only() {
                display: none;
            }
        }

        &.left {
            @include media-desktop-down() {
                display: none;
            }
        }

        &.left:after {
            left: 0;
            @include backgroundImage('images/about-side-left.png');
            background-position-x: left;
            background-position-y: bottom;

        }
        &.right:after {
            right: 0;
            @include backgroundImage('images/about-side-right.png');
            background-position-x: right;
            background-position-y: bottom;
            
        }
        background-size: cover;
        background-position: bottom center;
	}

	.content { 
        box-sizing: border-box;
        padding: 2% 3% 3%;
        width: 40%;
        background-color: rgba(#1e7177, 0.75);
        box-sizing: border-box;

        @include media-desktop-down() {
            padding: 20px 15px 15px;
            width: 65%;
        }

        @include media-tablet-down() {
            width: 50%;
        }

        @include media-phone-only() {
            width: 100%;
        }

		.section-title { 
            
            width: 100%;
            margin-bottom: 1em;
            

            @include media-desktop-down() {
                padding-top: 0.25em;
            }
		}

		p, li { 
            color: $white;
            margin-bottom: 2em;
            font-size: 1.2em;
            line-height: 120%;
            @include media-desktop-down() {
                font-size: 0.9em;
            }
        }

		.map { 
            height: auto;
            width: 90%;
            margin: 0 auto -170px;
            display: block;
            
            @include media-desktop-down() {
                width: 70%;
                margin-bottom: -100px;
                margin-top: -20px;
            }
            
		}
	}
}